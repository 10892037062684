import { toggleHitListCounterTopBarUpdateAction } from 'actions/hitListAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { eventListPath } from '../EventList.paths'
import { useHitsGroupQuery } from '../__generated__/HitsGroup'

export const useHitsCounter = (): number => {
  const dispatch = useDispatch()
  const { vip, needTopBarUpdate } = useShallowEqualSelector(
    ({ userReducer: { vip }, hitList: { needTopBarUpdate } }) => ({
      vip,
      needTopBarUpdate,
    })
  )

  const location = useLocation()
  const isEventsPage = location.pathname.includes(eventListPath)

  const { data, loading, refetch } = useHitsGroupQuery()
  const hitsUnion = data?.my?.hitList

  useEffect(() => {
    if (needTopBarUpdate) {
      dispatch(toggleHitListCounterTopBarUpdateAction(false))
      refetch()
    }
  }, [needTopBarUpdate])

  /**
   * https://redmine.mamba.ru/issues/115728
   * Если вип, вовзаращет настоящее число новых хитов.
   * Если нет, то возвращаем сумму всех favorites и всех likes + новые visits
   */

  return useMemo(() => {
    if (!loading && hitsUnion) {
      if (vip) {
        if (isEventsPage) {
          return 0
        }
        return hitsUnion.newHitsCounters.all
      } else {
        const hitsUnionFavoritesLikes =
          hitsUnion.hitCounters.favorites + hitsUnion.hitCounters.likes
        if (isEventsPage) {
          return hitsUnionFavoritesLikes
        }

        return hitsUnionFavoritesLikes + hitsUnion.newHitsCounters.visits
      }
    }

    // При нуле мы ничего не показываем.
    return 0
  }, [vip, loading, hitsUnion, isEventsPage])
}
