import styled, { css } from 'styled-components'
import { Colors } from 'common-constants/colors'

interface ProfileAvatarProps {
  $borderRadius?: number
  $hasBlur?: boolean
  $objectFit?: string
}

export const profileAvatarCss = css<ProfileAvatarProps>`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.$borderRadius}px;
  overflow: hidden;
  ${(props) => props.$hasBlur && 'filter: blur(10px)'};
  object-fit: ${(props) => (props.$objectFit ? props.$objectFit : 'inherit')};
  outline: none;
  vertical-align: middle;
`

export const ProfileAvatarWrapperEmpty = styled.div<ProfileAvatarProps>`
  background-color: ${Colors.noUserPhotoBgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${profileAvatarCss};
`

export const ProfileAvatarWrapper = styled.img<ProfileAvatarProps>`
  ${profileAvatarCss};
`

export const ProfileAvatarEmptyWithoutIcon = styled.div<ProfileAvatarProps>`
  ${profileAvatarCss}
  background: linear-gradient(135deg, #A2AEBC 0%, #7E8FA2 100%);
`
