import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { fetchAlbumsIfNeededAction } from 'actions/photo/photoAction'
import { TopUpSvg } from 'components/presentational/svg/TopUpSvg'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { RouterLink } from 'components/presentational/link'
import { stepSize } from 'components/presentational'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { storeFrontProfileUpPath } from 'components/storefront/StoreFront.paths'

const LinkCss = css`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  padding: 8px 15px;
  box-sizing: border-box;
  color: ${(props) => props.$linkColor};

  svg path {
    stroke: currentColor;
  }

  &:hover {
    color: ${(props) => props.$linkHoverColor};
    background-color: ${(props) => props.$backgroundHoverColor};

    svg path {
      stroke: currentColor;
    }
  }

  @media (max-width: ${stepSize}px) {
    display: none;
  }
`

const Link = styled(RouterLink)`
  ${LinkCss};
`

export const TopUpItem = ({ properties }) => {
  const dispatch = useDispatch()
  const { baseUrl } = useLayoutContext()
  useEffect(() => {
    dispatch(fetchAlbumsIfNeededAction())
  }, [])

  return (
    <Link
      to={mergeAllUrls(baseUrl, storeFrontProfileUpPath)}
      data-name={'link-profile-up-action'}
      {...properties}
    >
      <TopUpSvg />
    </Link>
  )
}
