import { useFeaturedPhotosPromoX3 } from 'components/block/FeaturedPhotosPromoX3/hooks/useFeaturedPhotosPromoX3'
import { eventListPath } from 'components/page/EventList/EventList.paths'
import { createEventListPath } from 'components/page/EventList/function/createEventListPath'
import { useHitsCounter } from 'components/page/EventList/hook/useHitsCounter'
import { isPathSelected } from 'components/presentational/Navigation/function/isPathSelected'
import { NavigationCounter } from 'components/presentational/Navigation/NavigationCounter/NavigationCounter'
import { NavigationLink } from 'components/presentational/Navigation/RightSide/Link/Link'
import { EventMailRuSvg } from 'components/presentational/svg/EventMailRuSvg'
import React from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { featuredPhotosX3IconStyles } from './common.styles'

export const Activity = () => {
  const location = useLocation()
  const isSelected = isPathSelected(eventListPath, location.pathname)
  const hitsCounter = useHitsCounter()
  const { isFeaturedPhotosPromoX3Active } = useFeaturedPhotosPromoX3()

  return (
    <NavigationLink
      to={createEventListPath()}
      selected={isSelected}
      data-name={'hitlist-action'}
      scrollToTop={true}
    >
      <NavigationCounter counter={hitsCounter} data-name={'hitlist-counter'}>
        <EventMailRuSvg />
        {isFeaturedPhotosPromoX3Active && <FeaturedPhotosX3PromoIcon />}
      </NavigationCounter>
    </NavigationLink>
  )
}

const FeaturedPhotosX3PromoIcon = styled.div`
  ${featuredPhotosX3IconStyles};
`
