import { isLoveMailru, mainPartnerIds } from 'common/constants'
import {
  allHeadersMailRuHeight,
  headerHeight,
  photoLineRealHeight,
} from 'components/layout/MambaLayout/MambaLayout.constants'

export const mainCometId = 'main'
export const hitListCometId = 'hitList'

export const canUseMenuDesign = (partnerId: number) =>
  mainPartnerIds.indexOf(partnerId) <= -1

export const resolveHeaderHeight = (partnerId: number): number => {
  if (partnerId) {
    return isLoveMailru(partnerId) ? allHeadersMailRuHeight : headerHeight
  }

  return headerHeight
}

export const resolveHeaderPhotoLineHeight = (partnerId: number): number => {
  return resolveHeaderHeight(partnerId) + photoLineRealHeight
}
