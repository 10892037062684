import { isLoveMailru, settingPartner } from 'common/constants'
import { canUseMenuDesign } from 'components/layout/MambaLayout/MambaLayout.functions'
import { Colors } from 'common-constants/colors'

export interface PartnerColors {
  $linkColor: string
  $linkHoverColor: string
  $linkSelectedColor: string
  $backgroundHoverColor: string
  $backgroundSelectedColor: string
}

export const findPartnerColors = (
  partner: any,
  partnerId: number
): PartnerColors => {
  if (isLoveMailru(partnerId)) {
    return settingPartner.mailru
  }

  if (partner.partnerMenuDesign && canUseMenuDesign(partnerId)) {
    const { activeLinkColor, inactiveLinkColor } = partner.partnerMenuDesign
    return {
      $linkColor: inactiveLinkColor,
      $linkHoverColor: activeLinkColor,
      $linkSelectedColor: inactiveLinkColor,
      $backgroundHoverColor: 'transparent',
      $backgroundSelectedColor: 'transparent',
    }
  }

  return {
    $linkColor: Colors.link,
    $linkHoverColor: Colors.linkHover,
    $linkSelectedColor: Colors.linkSelected,
    $backgroundHoverColor: 'transparent',
    $backgroundSelectedColor: 'transparent',
  }
}
