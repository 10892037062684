import React, { FC } from 'react'

export const NoPhotoWomanSvg: FC<{ id?: string | number }> = ({ id }) => (
  <svg
    viewBox="0 0 400 400"
    fill="none"
    style={{ width: '100%', height: '100%' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H400V400H0V0Z"
      fill="#7E8FA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M314.906 322.002C314.906 335.012 328.628 326.028 339.444 318.637C350.254 311.24 368.16 293.832 372.052 274.543C375.937 255.26 375.51 236.395 356.542 221.51C337.575 206.62 298.59 174.27 300.54 128.339C302.495 82.408 297.37 76.327 290.452 64.762C283.534 53.198 269.174 35.931 254.709 28.765C240.244 21.592 216.892 4.19699 199.237 3.27299C181.59 2.35999 160.58 0.209989 138.227 11.762C115.866 23.307 114.12 49.056 114.12 49.056C114.12 49.056 113.785 63.709 107.307 71.337C100.823 78.966 89.475 91.905 88.95 112.747C88.425 133.585 89.705 160.894 85.327 173.037C80.943 185.173 67.246 197.187 59.731 203.21C52.216 209.233 38.145 219.622 31.628 240.267C25.111 260.911 23.798 276.566 30.112 291.077C36.432 305.583 31.634 328.327 39.852 331.005C46.664 333.226 62.317 327.255 62.317 327.255L314.907 322.002H314.906Z"
      fill={`url(#paint0_linear_${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M324.357 399.04H29L33.926 376.072C37.186 362.66 39.466 353.92 40.769 349.852C45.155 336.124 48.752 327.179 50.666 322.805C52.581 318.432 63.73 306.945 76.39 302.348C89.043 297.75 117.278 289.356 131.878 281.903C146.486 274.449 147.148 264.263 148.678 252.769C150.202 241.269 147.685 204.112 146.141 185.536C144.591 166.961 131.839 167.019 130.878 154.488C129.918 141.958 135.038 140.766 140.172 140.004C145.3 139.249 151.805 149.5 155.382 149.417C158.967 149.34 158.867 145.236 158.867 145.236C158.867 145.236 157.715 134.005 164.233 125.213C170.745 116.415 170.361 109.5 170.361 109.5C178.629 118.624 199.881 117.958 221.563 116.409C243.239 114.859 246.73 120.846 246.73 120.846C246.73 120.846 247.605 129.702 244.418 156.601C241.232 183.494 234.839 197.702 230.526 204.931C226.22 212.167 217.522 213.646 206.836 216.258C196.151 218.871 199.278 243.676 199.278 243.676C199.278 243.676 199.642 249.727 200.874 264.416C202.106 279.098 222.822 284.278 222.822 284.278C236.356 288.754 265.551 292.884 282.622 295.548C299.702 298.211 311.062 305.76 317.628 318.605C317.628 318.605 330.76 340.62 339.095 368.388C339.65 370.236 340.428 373.06 341.43 376.86L348 399.5L324.357 399.04Z"
      fill={`url(#paint1_linear_${id})`}
    />
    <defs>
      <linearGradient
        id={`paint0_linear_${id}`}
        x1="189.616"
        y1="-50.6141"
        x2="198.898"
        y2="314.055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E8FA2" />
        <stop offset="1" stopColor="#576980" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${id}`}
        x1="188.5"
        y1="-14.9767"
        x2="188.5"
        y2="388.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C3CEDA" />
        <stop offset="0.91335" stopColor="#8293A5" />
        <stop offset="1" stopColor="#7E8FA2" />
      </linearGradient>
    </defs>
  </svg>
)
