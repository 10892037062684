import { mergeAllUrls } from 'functions/mergeAllUrls'
import { profilePath, inlineNoticePath } from 'components/paths'
import { buildQuery } from 'functions/queryString'
import { profileNotFoundNoticeId } from 'components/uninotice/uniNoticeIdList'
import { StatusProfile } from 'common-constants/statusProfile'
import { ProfileHit } from 'common/constants'

/**
 * Ссылка на пользователя.
 * @param locale, если вдруг не авторизован.
 * @param userId если нет, то ссылка на профиль не найден
 * @param hitId откуда
 * @param additionalPath сейчас используется только для поиска (курсор)
 * @param profileStatus
 */
export const createUserProfileUrl = (
  userId: number | undefined | null,
  hitId?: ProfileHit,
  locale?: string,
  additionalPath?: string,
  profileStatus?: StatusProfile
) => {
  if (!userId || profileStatus === StatusProfile.Deleted) {
    return mergeAllUrls(inlineNoticePath, profileNotFoundNoticeId)
  }

  if (!hitId) {
    return mergeAllUrls(locale, profilePath, userId)
  }

  const query = buildQuery({
    hit: hitId,
  })

  const mainUrl = mergeAllUrls(locale, profilePath, userId)

  if (additionalPath) {
    const additionalUrl = mergeAllUrls(mainUrl, additionalPath)

    return `${additionalUrl}?${query}`
  }

  return `${mainUrl}?${query}`
}
