import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { IconSwitch } from 'components/presentational/Navigation/IconSwitch'
import { LayoutLink } from 'components/presentational/Navigation/Link/Link.styled'
import { Text } from 'components/presentational/Navigation/Link/styled'
import { Icon } from 'components/presentational/Navigation/Navigation.styled'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { MambaNavigationLink } from 'components/presentational/Navigation/Link/LinkMambaRu.styled'
import { WithDataName } from 'common/types'
import { isV2NavigationIconsEnabled } from 'components/presentational/Navigation/function/isV2NavigationIconsEnabled'
import { Colors } from 'common-constants/colors'
import { MenuItem } from '../Navigation.types'

export const LinkSwitch: FC<
  { partnerId: number; isSelected: boolean } & MenuItem &
    WithDataName &
    PropsWithChildren
> = ({
  children,
  to,
  isExternalLink,
  renderIcon,
  iconDefault,
  iconHover,
  iconActive,
  partnerId,
  isSelected,
  ...rest
}) => {
  const colors = usePartnerColors()

  if (isV2NavigationIconsEnabled(partnerId)) {
    return (
      <MambaNavigationLink
        to={to}
        scrollToTop={true}
        data-name={rest['data-name']}
        isExternalLink={isExternalLink}
        $selected={isSelected}
        {...colors}
      >
        <IconSwitch
          iconDefault={iconDefault}
          iconHover={iconHover}
          iconActive={iconActive}
          isSelected={isSelected}
        />
        <Text data-name="navigation-item">{children}</Text>
      </MambaNavigationLink>
    )
  }

  return (
    <LayoutLink
      to={to}
      scrollToTop={true}
      data-name={rest['data-name']}
      $selected={isSelected}
      $partnerId={partnerId}
      isExternalLink={isExternalLink}
      {...colors}
    >
      <Icon>
        {renderIcon &&
          renderIcon(
            isSelected
              ? (colors.$linkSelectedColor as Colors)
              : (colors.$linkColor as Colors)
          )}
      </Icon>
      <Text>{children}</Text>
    </LayoutLink>
  )
}
