import styled, { css } from 'styled-components'
import { zIndex } from 'components/presentational/zIndex'
import {
  maxDesktopSize,
  media,
  sizes,
  stepSize,
  tabletMiddleSize,
} from 'components/presentational'
import { HeaderHeight } from 'common-constants/header.height'
import { desktopWidthPadding } from 'components/layout/MambaLayout/WidthLimit'
import { mailNavMarginBottom } from 'components/presentational/Navigation/Navigation.constants'

const headerCss = css<HeaderProps>`
  display: flex;
  align-items: center;
  position: relative;
  height: ${HeaderHeight.common}px;
  padding: 0 ${desktopWidthPadding}px;
  max-width: ${maxDesktopSize}px;
  box-sizing: border-box;
  margin: 0 auto;

  @media (max-width: ${stepSize}px) {
    height: 40px;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
  }

  ${media.phone<HeaderProps>`
    padding: ${({ $authorized, $hasUserRestrictions }) =>
      $authorized && !$hasUserRestrictions ? 0 : '0 10px'};
  `}
`

interface HeaderProps {
  $authorized?: boolean
  $hasUserRestrictions: boolean
}

export const Header = styled.header<HeaderProps>`
  ${headerCss}
`

export const HeaderWidthLimit = styled.div<{
  $color: string
}>`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: ${zIndex.navigationHeader};
  box-shadow: 0 0 10px 0 white;
  background-color: ${(props) => props.$color};
`

HeaderWidthLimit.defaultProps = {
  $color: 'white',
}

export const Icon = styled.span`
  display: none;

  @media (max-width: ${tabletMiddleSize}px) {
    display: block;
  }
`

export const IconVisibilityAllTime = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
`

export const IconCommonCss = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 24px;
  width: 24px;
  transition: opacity 0.1s ease-in-out;
`

export const IconDefault = styled.span`
  ${IconCommonCss}
`

export const IconHover = styled.span`
  ${IconCommonCss};
  visibility: hidden;
`

export const IconActive = styled.span`
  ${IconCommonCss};
`

interface HeaderMailRuProps {
  $shadow?: string
  $color?: string
  $hasUserRestrictions: boolean
}

export const HeaderMailRu = styled(HeaderWidthLimit)<HeaderMailRuProps>`
  background-color: ${(props) => props.$color};
  margin-bottom: ${mailNavMarginBottom}px;
  box-shadow: ${(props) => props.$shadow};

  @media (max-width: ${stepSize}px) {
    margin-bottom: ${({ $hasUserRestrictions }) =>
      $hasUserRestrictions ? '10px' : 0};
  }
`

export const HeaderInnerMailRu = styled.header<HeaderProps>`
  ${headerCss};

  height: ${HeaderHeight.headerMailRuHeight}px;
  padding: 0 20px;

  @media (max-width: ${stepSize}px) {
    ${(props) => props.theme.paddingLeft}: 44px;
    height: 48px;
  }

  ${media.phone`
      height: 88px;
  `}
`

export const WrapHeader = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  ${media.phone`
    flex-direction: column;
  `}
`

export const Gap = styled.div`
  flex-grow: 1;
  flex-shrink: 0;

  @media (max-width: ${stepSize}px) {
    display: none;
  }
`

interface ItemHeaderProps {
  $authorized?: boolean
}

const ItemHeaderCss = css<ItemHeaderProps>`
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${stepSize}px) {
    height: 48px;
  }

  @media (max-width: ${sizes.mailTopMenu}px) {
    flex-grow: 1;
    flex-shrink: 0;

    ${(props) =>
      !props.$authorized
        ? 'justify-content: flex-start'
        : 'justify-content: space-between'};

    ${(props) => props.theme.paddingLeft}: 0;
  }
`

export const ItemHeader = styled.div<ItemHeaderProps>`
  ${ItemHeaderCss};
`

export const ItemHeaderCustom = styled.div<ItemHeaderProps>`
  ${ItemHeaderCss};
  flex-grow: 1;
  flex-shrink: 0;

  ${media.tablet<ItemHeaderProps>`
    ${(props) => props.theme.paddingLeft}: 20px;
    ${(props) => !props.$authorized && 'justify-content: flex-end'};  
    
    ${(props) =>
      !props.$authorized &&
      `   
      div:nth-child(1){
        display: none;
      }
    `};
  `}

  ${media.phone<ItemHeaderProps>`
    height: 40px;
    ${(props) => props.theme.marginLeft}: -34px;
    ${(props) => props.theme.marginRight}: -10px;
    ${(props) => props.theme.paddingLeft}: 0;
    ${(props) => !props.$authorized && props.theme.marginLeft + ': -8px'};
    ${(props) => !props.$authorized && 'justify-content: flex-start'};  
  `}
`

export const WrapperFlex = styled.div`
  display: flex;
  align-items: center;
`
