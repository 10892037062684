import React, { FC } from 'react'
import {
  ProfileAvatarEmptyWithoutIcon,
  ProfileAvatarWrapper,
  ProfileAvatarWrapperEmpty,
} from 'components/presentational/ProfileAvatar/ProfileAvatar.styled'
import { NoPhoto2Svg } from 'components/presentational/svg/NoPhoto2Svg'
import { GQLGender } from 'generated-graphql/graphql'
import { NoPhoto } from 'components/presentational/NoPhoto/NoPhoto'
import { WithClassName } from 'common/types'

export const ProfileAvatar: FC<
  {
    id?: string
    srcAvatar?: string | null
    type?: string | number
    alt?: string
    borderRadius?: number
    gender?: GQLGender | string
    hasBlur?: boolean
    objectFit?: string
  } & WithClassName
> = ({
  id,
  srcAvatar,
  type,
  alt,
  borderRadius = 10,
  gender,
  hasBlur = false,
  objectFit,
  className,
}) => {
  if (srcAvatar) {
    return (
      <ProfileAvatarWrapper
        src={srcAvatar}
        data-name={`${type}-photo`}
        alt={alt}
        $borderRadius={borderRadius}
        $hasBlur={hasBlur}
        $objectFit={objectFit}
        className={className}
      />
    )
  }

  /**
   * https://youtrack.mamba.ru/issue/M-4514#focus=Comments-4-29995.0-0
   */
  if (!srcAvatar && hasBlur) {
    return (
      <ProfileAvatarEmptyWithoutIcon
        $borderRadius={borderRadius}
        data-name={`${type}-no-photo`}
      />
    )
  }

  if (gender) {
    return (
      <ProfileAvatarWrapperEmpty
        $borderRadius={borderRadius}
        $hasBlur={hasBlur}
        data-name={`${type}-no-photo`}
        className={className}
      >
        <NoPhoto gender={gender} id={id!} />
      </ProfileAvatarWrapperEmpty>
    )
  }

  return (
    <ProfileAvatarWrapperEmpty
      $borderRadius={borderRadius}
      $hasBlur={hasBlur}
      data-name={`${type}-no-photo`}
      className={className}
    >
      <NoPhoto2Svg />
    </ProfileAvatarWrapperEmpty>
  )
}
