import React, { FC } from 'react'
import { NavigationPlace } from '../Navigation.constants'
import { Link } from 'components/presentational/Navigation/Link/Link'
import { usePhotolineEnabled } from 'hooks/usePhotolineEnabled'
import { useShowLinkBlog } from 'hooks/useShowLinkBlog'

export const mainMenu = [NavigationPlace.rating, NavigationPlace.search]

export const Links: FC = () => {
  const isPhotolineEnabled = usePhotolineEnabled()
  const hasLinkBlog = useShowLinkBlog()

  const mainMenuExtended = hasLinkBlog
    ? [...mainMenu, NavigationPlace.blog]
    : [...mainMenu]

  if (!isPhotolineEnabled) {
    return (
      <>
        {mainMenuExtended.map((name) => (
          <Link key={name} name={name} />
        ))}
      </>
    )
  }

  if (isPhotolineEnabled) {
    const _mainMenu = [NavigationPlace.feed, ...mainMenuExtended]
    return (
      <>
        {_mainMenu.map((name) => (
          <Link key={name} name={name} />
        ))}
      </>
    )
  }

  const _mainMenu = [NavigationPlace.feed, ...mainMenuExtended]

  return (
    <>
      {_mainMenu.map((name) => (
        <Link key={name} name={name} />
      ))}
    </>
  )
}
