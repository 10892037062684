import React, { FC } from 'react'

export const EventMailRuSvg: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5C9.24281 4.20128 8.65805 3.70195 8.43011 3.13777L8.28846 2.73668C8.06052 2.17251 7.49838 1.86745 7.03278 2.05556C6.56733 2.24362 6.37473 2.85361 6.60267 3.41779L6.74432 3.81888C6.97226 4.38305 6.75719 4.79872 8 4.5Z"
      fill="white"
    />
    <path
      d="M4.71889 19.7031C5.45601 18.0707 5.95282 15.8649 5.01546 13.5448C3.17347 8.98574 3.72336 5.60483 7.60668 3.88979C7.60577 3.88754 7.60444 3.88376 7.60444 3.88376C7.65312 3.8641 7.70081 3.84745 7.74922 3.82864C7.79712 3.80854 7.84299 3.78739 7.89183 3.76765C7.89183 3.76765 7.8935 3.77128 7.89441 3.77354C11.8792 2.30966 14.6233 4.35969 16.4653 8.91879C17.4027 11.2388 19.2923 12.4804 20.9565 13.1426C21.8927 13.515 21.83 14.8045 20.8632 15.1951L14.0264 17.9574L13.0486 18.3524L6.21182 21.1147C5.24505 21.5053 4.3042 20.6212 4.71889 19.7031"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0297 20.4497C15.7744 21.2843 16.9902 21.6054 18.087 21.1623C19.1841 20.719 19.8357 19.6429 19.7912 18.5248L19.7833 18.4249C19.745 18.1037 19.5571 17.6268 18.7411 17.9565L15.3715 19.3179V19.3179C14.4946 19.6722 14.8261 20.2215 15.0297 20.4497Z"
      fill="#ECF0F6"
    />
  </svg>
)
