import React, { FC } from 'react'

export const NoPhotoManSvg: FC<{ id?: string | number }> = ({ id }) => (
  <svg
    viewBox="0 0 400 400"
    fill="none"
    style={{ width: '100%', height: '100%' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H400V400H0V0Z"
      fill="#7E8FA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M339.51 328.416C339.105 314.716 322.016 297.357 305.151 287.764C288.291 278.171 258.177 274.064 241.711 268.124C225.251 262.185 202.766 260.812 170.243 262.185C137.72 263.558 106.803 279.998 83.51 283.651C60.222 287.311 35.733 302.384 27.7 325.676C27.7 325.676 12.672 376.946 36.364 380.606C60.05 384.259 128.339 382.645 170.164 382.645C211.991 382.645 312.481 381.152 328.684 381.152C356.409 381.152 339.908 342.122 339.51 328.416V328.416Z"
      fill={`url(#paint0_linear_${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.783 240.757C220.783 240.757 219.171 254.826 223.2 264.877C227.235 274.927 248.996 270.907 250.202 285.382C251.414 299.85 234.081 301.463 214.736 299.85C195.398 298.243 131.318 293.42 128.095 278.946C128.095 278.946 126.077 275.331 136.958 272.92C147.839 270.509 151.868 265.68 153.48 250.408C155.097 235.13 154.286 185.682 150.662 163.974C147.033 142.266 135.347 107.286 148.645 98.0401C161.943 88.7991 183.305 106.887 187.74 149.905C192.169 192.916 200.632 233.118 220.783 240.757"
      fill={`url(#paint1_linear_${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M263.1 126.186C262.606 136.538 266.864 153.571 275.873 177.286C277.229 180.855 275.434 184.847 271.865 186.203L271.831 186.216C267.535 187.824 265.251 188.899 265.251 188.899C265.251 188.899 260.277 193.861 263.367 199.082C263.367 199.082 266.862 214.626 254.77 220.259C242.678 225.885 203.455 230.17 191.63 194.792C179.815 159.419 185.722 104.477 185.722 104.477C185.722 104.477 187.334 87.0541 208.557 81.6941C229.785 76.3341 257.993 77.6741 260.277 92.4141C260.277 92.4141 263.907 109.3 263.1 126.186V126.186Z"
      fill={`url(#paint2_linear_${id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.768 196.4C266.055 192.115 264.978 188.9 258.264 190.507C251.546 192.115 244.021 193.185 234.885 201.228C225.755 209.266 216.619 207.925 210.705 204.981C204.799 202.032 198.885 192.647 197.007 185.951C195.129 179.248 189.75 156.736 190.827 147.091C191.9 137.44 187.605 115.731 200.77 102.333C213.929 88.929 234.08 95.365 234.08 95.365C234.08 95.365 250.262 99.384 257.997 92.953C257.997 92.953 260.142 92.682 260.682 94.827C261.221 96.972 265.783 96.972 268.472 96.168C271.157 95.365 286.739 88.662 288.89 71.245C291.04 53.822 279.22 43.638 273.307 39.885C267.395 36.132 256.919 36.132 246.711 38.549C236.502 40.961 221.992 42.569 219.308 36.133C216.618 29.703 214.468 23 202.915 23C191.361 23 178.735 24.342 164.765 39.885C164.765 39.885 156.974 52.215 145.42 54.625C133.867 57.037 116.406 64.543 122.047 99.384C127.687 134.224 130.105 129.13 127.96 140.926C125.809 152.717 130.644 163.976 136.017 172.014C141.391 180.052 141.391 190.236 141.391 190.236C141.391 190.236 140.852 198.008 148.643 198.008C156.44 198.008 161.808 192.115 164.765 182.735C167.721 173.35 174.435 156.736 162.886 146.553C151.333 136.37 155.096 127.256 159.124 121.896C163.154 116.536 170.944 115.194 174.434 120.56C177.93 125.915 181.692 137.173 181.425 158.61C181.153 180.052 181.692 206.589 187.605 221.057C193.512 235.531 200.497 241.962 210.705 246.253C220.92 250.538 242.41 255.36 244.827 255.898C247.244 256.436 262.027 258.31 269.012 247.86C275.997 237.405 277.342 232.848 274.38 223.474C271.43 214.089 275.725 209.266 275.725 209.266C275.725 209.266 279.487 200.691 272.768 196.4V196.4Z"
      fill={`url(#paint3_linear_${id})`}
    />
    <defs>
      <linearGradient
        id={`paint0_linear_${id}`}
        x1="159.729"
        y1="261.603"
        x2="163.212"
        y2="331.361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C3CEDA" />
        <stop offset="0.91335" stopColor="#8293A5" />
        <stop offset="1" stopColor="#7E8FA2" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${id}`}
        x1="219.495"
        y1="102.437"
        x2="164.432"
        y2="385.047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C3CEDA" />
        <stop offset="0.91335" stopColor="#8293A5" />
        <stop offset="1" stopColor="#7E8FA2" />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_${id}`}
        x1="226.428"
        y1="13.8437"
        x2="315.138"
        y2="301.321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C3CEDA" />
        <stop offset="0.91335" stopColor="#8293A5" />
        <stop offset="1" stopColor="#7E8FA2" />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_${id}`}
        x1="202.444"
        y1="-33.0199"
        x2="207.463"
        y2="256.335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E8FA2" />
        <stop offset="1" stopColor="#576980" />
      </linearGradient>
    </defs>
  </svg>
)
