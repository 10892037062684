import styled, { css } from 'styled-components'
import { media, stepSize } from 'components/presentational'

interface ItemMenuProps {
  $authorized?: boolean
}

const ItemMenuCss = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`

export const ItemMenu = styled.div<ItemMenuProps>`
  ${ItemMenuCss};

  @media (max-width: ${stepSize}px) {
    flex-grow: 1;
  }

  ${media.tabletLarge`
    &:nth-child(n + 6) {
      display: none;
    }    
  `}

  ${media.tablet<ItemMenuProps>`
   margin: 0;
  
  ${(props) =>
    !props.$authorized &&
    `   
      &:nth-child(n + 2),
      &:nth-child(n + 3),
      &:nth-child(n + 4),
      &:nth-child(n + 5) {
        display: none;
      }    
  `};    
  `}
`

interface LogoProps {
  $authorized?: boolean
  $userHasRestrictions: boolean
}

export const ItemMenuLogo = styled.div<LogoProps>`
  ${ItemMenuCss};
  margin: 0;
  ${(props) => props.theme.marginRight}: 20px;

  ${media.tablet`
    margin: 0;
  `}

  ${media.phone<LogoProps>`
    ${(props) =>
      props.$authorized && !props.$userHasRestrictions && 'display: none;'}
  `}
`

export const ItemMenuLogoMailru = styled(ItemMenuLogo)`
  ${media.tablet`
    display: flex;
  `}
`
