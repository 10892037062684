import React, { FC } from 'react'
import { GQLGender } from 'generated-graphql/graphql'
import { NoPhotoWomanSvg } from '../svg/NoPhotoWomanSvg'
import { NoPhotoManSvg } from '../svg/NoPhotoManSvg'

export const NoPhoto: FC<{
  gender?: GQLGender | string
  id: string | number
}> = ({ id, gender }) => {
  if (gender === GQLGender.F) {
    return <NoPhotoWomanSvg id={id} />
  }
  return <NoPhotoManSvg id={id} />
}
