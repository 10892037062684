import React, { FC } from 'react'
import { WithClassName } from 'common/types'

export const NoPhoto2Svg: FC<
  {
    size?: number
  } & WithClassName
> = ({ size = 60, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 120 120"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <path
          d="M23.787 0c-5.14.065-10.217 3.865-11.36 8.509v.004c-.705 2.863-.662 5.23.166 8.165 1.626 7.665 6.248 12.919 11.281 12.919 4.885 0 9.463-5.068 11.141-12.325.833-2.803 1.192-5.29.337-8.76C34.2 3.837 29.057.02 23.896 0h-.11zM0 53.135s.294-5.428 1.764-11.415c0 0 .396-2.543 3.23-4.207 0 0 10.276-5.095 18.789-5.645l.166-.02.268.02c8.513.55 18.794 5.645 18.794 5.645 2.833 1.664 3.22 4.207 3.22 4.207C47.702 47.707 48 53.135 48 53.135H0z"
          transform="translate(36 30)"
        />
      </g>
    </g>
  </svg>
)
