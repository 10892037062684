import React from 'react'
import { Colors } from 'common-constants/colors'

export const TopUpSvg = ({ stroke = Colors.link }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke={stroke}
      strokeWidth="1.5"
      d="M12 2l-9 8.966h4.846V22h8.334V10.966H21z"
    />
  </svg>
)
