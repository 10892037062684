import { chatPath, contactListPath, contactRequestPath } from 'components/paths'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isPathSelected } from 'components/presentational/Navigation/function/isPathSelected'

export const useChatSelected = () => {
  const { pathname } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => ({ pathname })
  )

  return (
    isPathSelected(chatPath, pathname) ||
    isPathSelected(contactListPath, pathname) ||
    isPathSelected(contactRequestPath, pathname)
  )
}
