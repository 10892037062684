/* eslint-disable */
import * as Types from '../../../../../generated/graphql'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GQLHitsGroupQueryVariables = Types.Exact<{ [key: string]: never }>

export type GQLHitsGroupQuery = {
  __typename?: 'Query'
  my: {
    __typename?: 'My'
    id: any
    vip: boolean
    hitList: {
      __typename?: 'HitList'
      newHitsCounters: {
        __typename?: 'HitGroupCounters'
        all: number
        visits: number
        likes: number
        favorites: number
      }
      hitCounters: {
        __typename?: 'HitGroupCounters'
        all: number
        visits: number
        likes: number
        favorites: number
      }
    }
  }
}

export const HitsGroupDocument = gql`
  query HitsGroup {
    my {
      id
      hitList {
        newHitsCounters {
          all
          visits
          likes
          favorites
        }
        hitCounters(period: MONTH) {
          all
          visits
          likes
          favorites
        }
      }
      vip
    }
  }
`

/**
 * __useHitsGroupQuery__
 *
 * To run a query within a React component, call `useHitsGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useHitsGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHitsGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useHitsGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLHitsGroupQuery,
    GQLHitsGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GQLHitsGroupQuery, GQLHitsGroupQueryVariables>(
    HitsGroupDocument,
    options
  )
}
export function useHitsGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLHitsGroupQuery,
    GQLHitsGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GQLHitsGroupQuery, GQLHitsGroupQueryVariables>(
    HitsGroupDocument,
    options
  )
}
export type HitsGroupQueryHookResult = ReturnType<typeof useHitsGroupQuery>
export type HitsGroupLazyQueryHookResult = ReturnType<
  typeof useHitsGroupLazyQuery
>
export type HitsGroupQueryResult = Apollo.QueryResult<
  GQLHitsGroupQuery,
  GQLHitsGroupQueryVariables
>
