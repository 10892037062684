import React, { FC, PropsWithChildren, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { balloonColor } from 'common-constants/colors'
import { fontFamily } from 'common/constants'
import { useInterval } from 'hooks/useInterval'
import { TimeRange } from 'common-constants/timeRange'
import { WithDataName } from 'common/types'

const pulse = (length: number) => keyframes`
	0% {
		box-shadow: 0 0 0 0 rgba(224, 96, 101, 0.9);
	}
	
	70% {
		box-shadow: 0 0 0 ${length}px rgba(224, 96, 101, 0);
	}
	
	100% {
		box-shadow: 0 0 0 0 rgba(224, 96, 101, 0);
	}
`

const Balloon = styled.span`
  display: flex;
  align-items: center;
  background-color: ${balloonColor};
  background-image: linear-gradient(to bottom, #f2999f, #e06065);
  border-radius: 50px;
  font-size: 11px;
  line-height: 11px;
  color: white;
  box-sizing: border-box;
  text-align: center;
  font-family: ${fontFamily};
  padding: 2px 4px;
`

const Pulse = styled.div<{
  $time: number
  $delay: number
  $length: number
}>`
  display: flex;
  border-radius: 50px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: ${(props) => pulse(props.$length)} ${(props) => props.$time}s 1;
  animation-delay: ${(props) => props.$delay}s;
`
Pulse.defaultProps = {
  $length: 15,
}

const maximumValue = 99

export const PulseWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <Pulse $time={2.5} $delay={0} $length={10}>
    <Pulse $time={2} $delay={0.5} $length={7}>
      <Pulse $time={1.8} $delay={1} $length={5}>
        <Pulse $time={1.5} $delay={1.3} $length={3}>
          {children}
        </Pulse>
      </Pulse>
    </Pulse>
  </Pulse>
)

export const ContainerPulse: FC<
  { startAnimation: boolean } & WithDataName & PropsWithChildren<unknown>
> = ({ children, startAnimation, ...rest }) => {
  if (startAnimation) {
    return (
      <PulseWrapper>
        <Balloon data-name={rest['data-name']}>{children}</Balloon>
      </PulseWrapper>
    )
  }

  return <Balloon data-name={rest['data-name']}>{children}</Balloon>
}

export const Counter: FC<
  {
    value: number
    startAnimation?: boolean
  } & WithDataName
> = ({ value, startAnimation = true, ...rest }) => {
  const [key, setKey] = useState(0)

  useInterval(() => {
    setKey(key + 1)
  }, 15 * TimeRange.minute)

  if (value > maximumValue) {
    return (
      <ContainerPulse
        key={key}
        data-name={rest['data-name']}
        startAnimation={startAnimation}
      >
        99+
      </ContainerPulse>
    )
  }

  if (value) {
    return (
      <ContainerPulse
        key={key}
        data-name={rest['data-name']}
        startAnimation={startAnimation}
      >
        {value}
      </ContainerPulse>
    )
  }

  return null
}
