import React from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { profilePath } from 'components/paths'
import {
  StyledProfileAvatar,
  WrapperProfileAvatarUserPanel,
} from 'components/presentational/Navigation/RightSide/Authorized/UserPhotoLink/UserPhotoLink.styled'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { NavigationLink } from 'components/presentational/Navigation/RightSide/Link/Link'
import { isPathSelected } from 'components/presentational/Navigation/function/isPathSelected'
import { createUserProfileUrl } from 'components/presentational/ProfileLink/function/createUserProfileUrl'

export const UserPhotoLink = () => {
  const { smallPhoto, pathname, userId, gender } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        profile: { id },
      },
      userReducer: { smallPhoto, gender },
      router: {
        location: { pathname },
      },
    }) => ({
      smallPhoto,
      pathname,
      userId: id,
      gender,
    })
  )

  const isSelected = isPathSelected(mergeAllUrls(profilePath, userId), pathname)
  const colors = usePartnerColors()

  return (
    <NavigationLink
      to={createUserProfileUrl(userId)}
      data-name={'menu-user_profile-action'}
      selected={isSelected}
      scrollToTop={true}
    >
      <WrapperProfileAvatarUserPanel
        selected={isSelected}
        $linkSelectedColor={colors.$linkSelectedColor}
      >
        <StyledProfileAvatar
          srcAvatar={smallPhoto}
          borderRadius={5}
          id={`${String(userId)}-photo-avatar`}
          gender={gender!}
          type={String(userId)}
        />
      </WrapperProfileAvatarUserPanel>
    </NavigationLink>
  )
}
