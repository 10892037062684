import { mergeAllUrls } from 'functions/mergeAllUrls'
import { removeLocaleFromPath } from 'functions/language'

/**
 * Выбран ли путь в навигации
 * @param url путь для проверки
 * @param pathname полный путь
 */
export const isPathSelected = (url: string, pathname: string): boolean => {
  const pathWithoutLocale = mergeAllUrls(removeLocaleFromPath(pathname))
  return pathWithoutLocale.indexOf(url) === 0
}
