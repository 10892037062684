import React, { FC, ReactNode } from 'react'
import {
  IconVisibilityAllTime,
  IconActive,
  IconDefault,
  IconHover,
} from 'components/presentational/Navigation/Navigation.styled'

export const IconSwitch: FC<{
  className?: string
  iconDefault: ReactNode
  iconHover: ReactNode
  iconActive: ReactNode
  isSelected?: boolean
}> = ({ className, isSelected, iconDefault, iconHover, iconActive }) => {
  return (
    <IconVisibilityAllTime className={className} data-name="icon-visibility">
      {isSelected ? (
        <IconActive>{iconActive}</IconActive>
      ) : (
        <>
          <IconDefault>{iconDefault}</IconDefault>
          <IconHover>{iconHover}</IconHover>
        </>
      )}
    </IconVisibilityAllTime>
  )
}
