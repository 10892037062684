import React from 'react'

import { Colors } from 'common-constants/colors'

export const MessageDefaultSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="wq9ildo5ya"
        x1="50%"
        x2="50%"
        y1="-24.266%"
        y2="119.412%"
      >
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#wq9ildo5ya)" transform="translate(-984 -258)">
        <g>
          <path
            d="M23 11.362C23 7.735 20.491 2 12 2S1 7.853 1 11.362c0 3.51 3.176 9.081 10.34 9.081 2.358 0 2.188-.066 4.054-.25l4.534 1.737c.493.198 1.053-.038 1.253-.527.053-.13.076-.272.068-.412-.958-2.316-.98-4.003-.068-5.06C22.298 14.639 23 13.102 23 11.363z"
            transform="translate(984 258)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const MessageHoverSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="p5g3gknbwa"
        x1="50%"
        x2="50%"
        y1="-38.916%"
        y2="115.435%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#p5g3gknbwa)" transform="translate(-984 -303)">
        <g>
          <path
            d="M23 11.362C23 7.735 20.491 2 12 2S1 7.853 1 11.362c0 3.51 3.176 9.081 10.34 9.081 2.358 0 2.188-.066 4.054-.25l4.534 1.737c.493.198 1.053-.038 1.253-.527.053-.13.076-.272.068-.412-.958-2.316-.98-4.003-.068-5.06C22.298 14.639 23 13.102 23 11.363z"
            transform="translate(984 303)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const MessageActiveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="vn76d2ed0a"
        x1="50%"
        x2="50%"
        y1="-20.703%"
        y2="140.976%"
      >
        <stop offset="0%" stopColor="#FF9C70" />
        <stop offset="100%" stopColor="#F56323" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#vn76d2ed0a)" transform="translate(-984 -347)">
        <g>
          <path
            d="M23 11.362C23 7.735 20.491 2 12 2S1 7.853 1 11.362c0 3.51 3.176 9.081 10.34 9.081 2.358 0 2.188-.066 4.054-.25l4.534 1.737c.493.198 1.053-.038 1.253-.527.053-.13.076-.272.068-.412-.958-2.316-.98-4.003-.068-5.06C22.298 14.639 23 13.102 23 11.363z"
            transform="translate(984 347)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const MessageSvg = ({
  stroke = Colors.link,
  circle = 'transparent',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={stroke}
        strokeWidth="1.5"
        d="M20.037 17.238l.254 4.63-5.169-2.1a13.56 13.56 0 0 1-3.254.391C5.866 20.16 1 16.32 1 11.58 1 6.842 5.866 3 11.868 3c6.002 0 10.867 3.841 10.867 8.58 0 2.168-1.018 4.148-2.698 5.658z"
      />
      <circle cx="22" cy="2" r="2" fill={circle} />
    </g>
  </svg>
)
