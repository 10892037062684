import { isLoveMailru } from 'common/constants'
import { stepSize, tabletMiddleSize } from 'components/presentational'
import styled, { css } from 'styled-components'
import { IconDefault, IconHover } from '../Navigation.styled'
import { LinkCssProps } from './Link.styled'

export const loveMailruStyles = css<Partial<LinkCssProps>>`
  background-color: transparent;

  svg {
    opacity: 0.6;
    ${(props) =>
      props.$selected &&
      `
        opacity: 1;
    `};
  }

  &:hover {
    background-color: transparent;
  }
`

export const linkCommonCss = css`
  padding: 8px 10px;

  @media (hover: hover) {
    &:hover ${IconDefault} {
      visibility: hidden;
    }

    &:hover ${IconHover} {
      visibility: visible;
    }
  }
`

export const Text = styled.span`
  display: block;
  ${(props) => props.theme.marginLeft}: 10px;

  @media (max-width: ${tabletMiddleSize}px) {
    display: none;
  }
`

export const linkCss = css<LinkCssProps>`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  padding: 8px 15px;
  font-size: 15px;
  box-sizing: border-box;
  color: ${(props) =>
    props.$selected ? props.$linkSelectedColor : props.$linkColor};

  background-color: ${(props) =>
    props.$selected ? props.$backgroundSelectedColor : props.$backgroundColor};

  &:hover {
    color: ${(props) =>
      props.$selected ? props.$linkSelectedColor : props.$linkHoverColor};

    background-color: ${(props) =>
      props.$selected
        ? props.$backgroundSelectedColor
        : props.$backgroundHoverColor};
  }

  @media (max-width: ${stepSize}px) {
    flex-grow: 1;
    justify-content: center;
    padding: 0;
    ${(props) => (isLoveMailru(props.$partnerId!) ? loveMailruStyles : '')}
  }
`
