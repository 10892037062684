import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import {
  findPartnerColors,
  PartnerColors,
} from 'components/presentational/Navigation/function/findPartnerColors'
import { useMemo } from 'react'

export const usePartnerColors = (): PartnerColors => {
  const { partner, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partner, partnerId } }) => ({
      partner,
      partnerId,
    })
  )

  return useMemo(() => findPartnerColors(partner, Number(partnerId)), [
    partner,
    partnerId,
  ])
}
