import React, { FC, useMemo } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { NAVIGATION_LINKS } from 'components/presentational/Navigation/NavigationLinks'
import { LinkSwitch } from 'components/presentational/Navigation/Link/LinkSwitch'
import { isPathSelected } from 'components/presentational/Navigation/function/isPathSelected'
import { ItemMenu } from 'components/presentational/Navigation/Link/ItemMenu.styled'
import { NavigationPlace } from 'components/presentational/Navigation/Navigation.constants'

export const Link: FC<{
  name: NavigationPlace
}> = ({ name }) => {
  const { partnerId, pathname, authorized } = useShallowEqualSelector(
    ({
      systemReducer: { partnerId },
      authorizationReducer: { authorized },
      router: {
        location: { pathname },
      },
    }) => ({ partnerId, pathname, authorized })
  )

  const {
    to,
    renderIcon,
    iconDefault,
    iconHover,
    iconActive,
    selectedPaths,
    ['data-name']: dataName,
    label,
    isExternalLink,
  } = NAVIGATION_LINKS[name]

  return (
    <ItemMenu $authorized={authorized}>
      <LinkSwitch
        to={to}
        isExternalLink={isExternalLink}
        renderIcon={renderIcon}
        iconDefault={iconDefault}
        iconHover={iconHover}
        iconActive={iconActive}
        partnerId={partnerId}
        data-name={dataName}
        isSelected={getIsSelected(selectedPaths, pathname, to)}
      >
        {label}
      </LinkSwitch>
    </ItemMenu>
  )
}

const getIsSelected = (
  selectedPaths: string[] | undefined,
  pathname: string,
  href: string | undefined
) => {
  if (selectedPaths?.length) {
    return Boolean(
      selectedPaths.some((selectedPath) =>
        isPathSelected(selectedPath, pathname)
      )
    )
  }

  if (href) {
    return isPathSelected(href, pathname)
  }

  return false
}
