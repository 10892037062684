import React, { FC } from 'react'
import { redirectChatPath } from 'components/paths'
import { useChatSelected } from 'components/presentational/Navigation/hooks/useChatSelected'
import { NavigationLink } from 'components/presentational/Navigation/RightSide/Link/Link'
import { MessengerIconSwitch } from 'components/presentational/Navigation/RightSide/Authorized/Messenger/MessengerIconSwitch'

export const Messenger: FC = () => {
  const isSelected = useChatSelected()

  return (
    <NavigationLink
      to={redirectChatPath}
      data-name={'messenger-action'}
      selected={isSelected}
      scrollToTop={true}
    >
      <MessengerIconSwitch isSelected={isSelected} />
    </NavigationLink>
  )
}
