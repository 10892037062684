import styled, { css } from 'styled-components'
import { stepSize } from 'components/presentational'
import {
  Link,
  LinkCssProps,
} from 'components/presentational/Navigation/Link/Link.styled'

const LinkMailRuCss = css<LinkCssProps>`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.1s linear;
  justify-content: center;
  color: ${(props) =>
    props.$selected ? props.$linkSelectedColor : props.$linkColor};

  @media (min-width: ${stepSize + 1}px) {
    background-color: ${(props) =>
      props.$selected ? props.$backgroundHoverColor : 'transparent'};
  }

  &:hover {
    background-color: ${(props) => props.$backgroundHoverColor};
    color: ${(props) =>
      props.$selected ? props.$linkSelectedColor : props.$linkHoverColor};
  }

  @media (max-width: ${stepSize}px) {
    padding: 0;
    flex-grow: 1;
    background-color: transparent;

    svg {
      opacity: 0.6;
      ${(props) =>
        props.$selected &&
        `
        opacity: 1;
      `};
    }

    &:hover {
      background-color: transparent;
    }
  }
`
export const LinkMailru = styled(Link)`
  ${LinkMailRuCss};
`
