import styled, { css } from 'styled-components'
import React, { FC, PropsWithChildren } from 'react'
import { Counter } from 'components/presentational/counter/Counter'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isMamba } from 'common/constants'

const WrapperCount: FC<PropsWithChildren> = ({ children }) => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  if (partnerId && isMamba(partnerId)) {
    return <MambaCounterWrapper>{children}</MambaCounterWrapper>
  }

  return <CounterWrapper>{children}</CounterWrapper>
}

export const NavigationCounter: FC<
  {
    hasCounter?: boolean
    counter: number
  } & PropsWithChildren
> = ({ children, counter, hasCounter = true }) => {
  return (
    <Content>
      {children}
      {hasCounter && counter !== 0 && (
        <WrapperCount>
          <Counter key={counter} value={counter} data-name={'total-count'} />
        </WrapperCount>
      )}
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

const wrapperCss = css`
  display: flex;
  position: absolute;
  z-index: 1;
  flex-direction: column;
  bottom: 12px;
`

const MambaCounterWrapper = styled.div`
  ${wrapperCss};
  ${(props) => props.theme.right}: -4px;
`

const CounterWrapper = styled.div`
  ${wrapperCss};
  ${(props) => props.theme.right}: -4px;
`
