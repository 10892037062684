import { PlainLink, RouterLink } from 'components/presentational/link'
import styled, { css } from 'styled-components'
import { linkCss } from './styled'
import { Colors } from 'common-constants/colors'

export interface LinkCssProps {
  $linkColor: string
  $linkHoverColor: string
  $linkSelectedColor: string
  $backgroundColor?: string
  $backgroundHoverColor: string
  $backgroundSelectedColor: string
  $selected?: boolean
  $partnerId?: number
}

export const LinkSvgCss = css<LinkCssProps>`
  svg path {
    stroke: ${(props) =>
      props.$selected ? props.$linkSelectedColor : props.$linkColor};
  }

  &:hover {
    svg path {
      stroke: ${(props) =>
        props.$selected ? props.$linkSelectedColor : props.$linkHoverColor};
    }
  }
`
export const Link = styled(RouterLink)`
  ${linkCss};
  ${LinkSvgCss};
`

export const LinkWithoutSvg = styled(RouterLink)`
  ${linkCss};
`

export const LayoutLink = styled(RouterLink)<LinkCssProps>`
  ${linkCss};
`

export const LayoutLegacyLink = styled(PlainLink)<LinkCssProps>`
  ${linkCss};
`

const cssProps = {
  $linkColor: Colors.link,
  $linkHoverColor: Colors.linkHover,
  $linkSelectedColor: Colors.linkSelected,
  background: 'transparent',
  backgroundHoverColor: 'transparent',
  opacity: 1,
}

LayoutLink.defaultProps = {
  ...cssProps,
}

LayoutLegacyLink.defaultProps = {
  ...cssProps,
}
