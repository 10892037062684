import React, { FC, ReactChild } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { usePartnerColors } from 'components/presentational/Navigation/hooks/usePartnerColors'
import { isLoveMailru, isMamba } from 'common/constants'
import {
  Link,
  LinkWithoutSvg,
} from 'components/presentational/Navigation/Link/Link.styled'
import { LinkMailru } from 'components/presentational/Navigation/Link/LinkMailRu.styled'

export interface NavigationLinkProps {
  to: string
  children: ReactChild[] | ReactChild
  tooltipHover?: boolean
  selected?: boolean
  scrollToTop?: boolean
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  children,
  ...rest
}) => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  const colors = usePartnerColors()
  const loveMailru = isLoveMailru(partnerId!)
  const isPartnerMamba = isMamba(partnerId!)

  if (loveMailru) {
    return (
      <LinkMailru {...colors} {...rest}>
        {children}
      </LinkMailru>
    )
  }

  if (isPartnerMamba) {
    return (
      <LinkWithoutSvg {...colors} {...rest}>
        {children}
      </LinkWithoutSvg>
    )
  }

  return (
    <Link {...colors} {...rest}>
      {children}
    </Link>
  )
}
