import {
  stepMiddleDesktopSize,
  tabletMiddleSize,
} from 'components/presentational'
import { RouterLink } from 'components/presentational/link'
import styled from 'styled-components'
import { LinkCssProps } from './Link.styled'
import { linkCommonCss, linkCss } from './styled'

export const MambaNavigationLink = styled(RouterLink)<LinkCssProps>`
  ${linkCss};
  ${linkCommonCss};

  @media (max-width: ${tabletMiddleSize}px) {
    & [data-name='icon-visibility'] {
      ${(props) => props.theme.marginRight}: 0;
    }

    & [data-name='navigation-item'] {
      display: none;
    }
  }
`

export const MambaRouterLink = styled(RouterLink)`
  ${linkCss};
  ${linkCommonCss};

  @media (max-width: ${stepMiddleDesktopSize}px) {
    & [data-name='icon-visibility'] {
      ${(props) => props.theme.marginRight}: 0;
    }

    & [data-name='navigation-item'] {
      display: none;
    }
  }
`
