import { ProfileAvatar } from 'components/presentational/ProfileAvatar/ProfileAvatar'
import styled from 'styled-components'

interface WrapperProfileAvatarUserPanelProps {
  selected: boolean
  $linkSelectedColor: string
}

export const WrapperProfileAvatarUserPanel = styled.div<
  WrapperProfileAvatarUserPanelProps
>`
  box-shadow: 0 0 0 1px
    ${(props) => (props.selected ? props.$linkSelectedColor : 'transparent')};
  padding: 0;
  border-radius: 5px;
  width: 24px;
  height: 24px;
`

export const StyledProfileAvatar = styled(ProfileAvatar)`
  max-width: 24px;
  min-width: 24px;
  height: 24px;
`
