import { Partner } from 'common/types'
import { isLoveMailru, settingPartner } from 'common/constants'
import { canUseMenuDesign } from 'components/layout/MambaLayout/MambaLayout.functions'
import { definitions } from 'api/generated/uniweb'

export const findMenuColor = (
  partner: definitions['PartnerShortInfo'],
  partnerId: number
) => {
  if (isLoveMailru(partnerId)) {
    return {
      color: settingPartner.mailru.color,
      shadow: settingPartner.mailru.shadow,
    }
  }

  if (
    ((partner as unknown) as Partner).partnerMenuDesign &&
    canUseMenuDesign(partnerId)
  ) {
    return {
      color: ((partner as unknown) as Partner).partnerMenuDesign
        .backgroundColor,
      shadow: 'none',
    }
  }
  return { color: 'white', shadow: '0 0 10px 0 white' }
}
