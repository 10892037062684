import featurePhotosPromoX3Svg from './assets/featurePhotosPromoX3.svg'
import { css } from 'styled-components'

export const featuredPhotosX3IconStyles = css`
  &::before {
    content: '';
    display: block;
    width: 57px;
    height: 41px;
    position: absolute;
    left: -17px;
    top: -13px;
    background-image: url(${featurePhotosPromoX3Svg});
    z-index: -1;
  }
`
