import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import {
  MessageSvg,
  MessageDefaultSvg,
  MessageHoverSvg,
  MessageActiveSvg,
} from 'components/presentational/svg/MessageSvg'
import { IconSwitch } from 'components/presentational/Navigation/IconSwitch'
import { WrapperFlex } from 'components/presentational/Navigation/Navigation.styled'
import { Text } from 'components/presentational/Navigation/Link/styled'
import { NavigationCounter } from 'components/presentational/Navigation/NavigationCounter/NavigationCounter'
import { isV2NavigationIconsEnabled } from 'components/presentational/Navigation/function/isV2NavigationIconsEnabled'

interface MessengerIconSwitchProps {
  isSelected: boolean
}

export const MessengerIconSwitch: FC<MessengerIconSwitchProps> = ({
  isSelected,
}) => {
  const { partnerId, messagesCounter } = useShallowEqualSelector(
    ({ systemReducer: { partnerId }, userReducer: { messagesCounter } }) => ({
      partnerId,
      messagesCounter,
    })
  )

  if (isV2NavigationIconsEnabled(partnerId)) {
    return (
      <WrapperFlex>
        <NavigationCounter
          counter={messagesCounter}
          data-name={'messages-counter'}
        >
          <IconSwitch
            iconDefault={<MessageDefaultSvg />}
            iconHover={<MessageHoverSvg />}
            iconActive={<MessageActiveSvg />}
            isSelected={isSelected}
          />
        </NavigationCounter>
        <Text data-name="navigation-item">
          <FormattedMessage
            id={'popularity.statistic.messenger'}
            defaultMessage={'Сообщения'}
          />
        </Text>
      </WrapperFlex>
    )
  }

  return (
    <NavigationCounter counter={messagesCounter} data-name={'messages-counter'}>
      <MessageSvg />
    </NavigationCounter>
  )
}
