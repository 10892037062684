import styled, { css } from 'styled-components'
import { stepSize } from 'components/presentational'
import { IconVisibilityAllTime } from 'components/presentational/Navigation/Navigation.styled'
import { MambaRouterLink } from 'components/presentational/Navigation/Link/LinkMambaRu.styled'

const anonymousPanelCss = css`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media (max-width: ${stepSize}px) {
    gap: 10px;

    ${MambaRouterLink} {
      ${IconVisibilityAllTime} {
        ${(props) => props.theme.marginRight} : 5px;
      }
    }
  }
`

export const AnonymousPanel = styled.div`
  ${anonymousPanelCss};
`

export const AnonymousPanelMailru = styled.div`
  ${anonymousPanelCss};

  height: 100%;
  align-items: center;
`
